import { Contract } from "@/model/api/Contract";
import { MessageModel } from "@/model/api/Message";
import { Shift } from "@/model/api/Shift";
import { User } from "@/model/api/User";
import {
  ContractAutocomplete,
  MessageEditor,
  ShiftAutocomplete,
  // TicketAutocomplete,
  UserAutocomplete
} from "@components";
import { messageService } from "@services/message.service";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";


type MessageTarget = 'user' | 'shift' | 'contract' | 'allUsers';
@Options({
  components: {
    ContractAutocomplete,
    MessageEditor,
    ShiftAutocomplete,
    // TicketAutocomplete,
    UserAutocomplete
  }
})
export default class TextPage extends Vue {
  target: MessageTarget  = 'allUsers';

  message: MessageModel = new MessageModel();

  user: User = null;

  shift: Shift = null; 

  contract: Contract = null; 

  @Watch('target')
  onTargetChange(newValue: MessageTarget, oldValue: MessageTarget) {
    if (newValue !== oldValue) {
      if (newValue === 'allUsers') {
        this.user = null; 
        this.shift = null;
        this.contract = null; 
      } else  {
        this[oldValue] = null; 
      }
    }
  }

  async send() {
    this.message.user_id     = this.user?.id;
    this.message.shift_id    = this.shift?.id;
    this.message.contract_id = this.contract?.id;

    this.message.all = this.target === 'allUsers';

    this.$waitFor( async () => {
      await messageService.send(this.message);

      this.$successMessage("Message sent");
      this.$emit("sent");

      this.reset();
    });
  }
  
  private reset() {
    this.message = new MessageModel();
  }
}