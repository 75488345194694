import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = { class: "p-field-checkbox" }
const _hoisted_4 = { for: "sms" }
const _hoisted_5 = { class: "p-field-checkbox" }
const _hoisted_6 = { for: "email" }
const _hoisted_7 = { class: "p-field-checkbox" }
const _hoisted_8 = { for: "push" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TextsFilters = _resolveComponent("TextsFilters")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('text.list.title')), 1),
      _createVNode(_component_router_link, { to: _ctx.newTextRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            label: "New"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_TextsFilters, {
      filters: _ctx.advFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => (_ctx.advFilters = $event)),
      onChange: _ctx.onFiltersChange
    }, null, 8, ["filters", "onChange"]),
    _createVNode(_component_AppTable, {
      ref: "table",
      filtersSchema: _ctx.filters,
      service: _ctx.service,
      stateKey: _ctx.stateKey,
      showActions: false,
      onResetFilters: _ctx.onReset
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_Column, {
          sortable: false,
          header: "Created AT",
          style: {"min-width":"8rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createVNode(_component_DateFormatted, {
              date: data.created_at,
              format: "YYYY-MM-DD hh:mm A"
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          sortable: false,
          header: "Full Name",
          style: {"min-width":"8rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", null, _toDisplayString(data.user?.name) + " " + _toDisplayString(data.user?.surname), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          sortable: false,
          header: "Type",
          style: {"min-width":"8rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Checkbox, {
                  modelValue: data.is_sms
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('message.sms')), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Checkbox, {
                  modelValue: data.is_email
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('message.email')), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Checkbox, {
                  modelValue: data.is_push
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('message.push')), 1)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          sortable: false,
          header: "Text",
          style: {"min-width":"8rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", null, _toDisplayString(data.text), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["filtersSchema", "service", "stateKey", "onResetFilters"])
  ]))
}