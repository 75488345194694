import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import {
  AppTable
} from "@components";
import Table from "@components/AppTable/Table";
import { messageService } from "@services/message.service";
import moment from "moment";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";
import { TextRoutesEnum } from "../../router";
import { TextFilters } from "./TextFilters";
import TextsFilters from "./TextsFilters.vue";

@Options({
  components: {
    AppTable,
    TextsFilters
  },

  beforeRouteEnter(to, from, next) {
    const linked = [
      TextRoutesEnum.SENT_TEXTS,
    ];

    if (from.name && linked.every(x => !(from.name as string).startsWith(x))) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_TEXTS);
      sessionStorage.removeItem(SessionStorageEnum.TEXTS_ADVANCED_FILTERS);
    }

    next();
  },

  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class TextListPage extends Vue {
  filters: any = null;

  advFilters: TextFilters = new TextFilters();

  get service() {
    return messageService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_TEXTS;
  }

  get newTextRoute() {
    return { name: TextRoutesEnum.SEND_TEXT };
  }

  get cols() {
    return [
      {
        field: 'user.name',
        header: this.$t('user.table.first_name'),
        placeHolder: "Name contains",
        sortable: false
      },
      {
        field: 'user.surname',
        header: this.$t('user.table.last_name'),
        placeHolder: "Surname contains",
        sortable: false
      },
      {
        field: 'user.email',
        header: this.$t('user.table.email'),
        placeHolder: "E-mail contains"
      },
      {
        field: 'text',
        header: this.$t('user.table.text'),
        placeHolder: "Text contains",
        sortable: false
      },
    ] as {
      field?: any, // keyof Text, 
      [key: string]: any
    }[]
  }

  onFiltersChange() {
    this._updateFilters();
  }

  onReset() {
    console.debug("on reset");
    this.advFilters = new TextFilters();

    this._updateFilters();
  }

  private _updateFilters() {
    const table = (this.$refs.table as Table);

    if (table?.filters) {
      const filters = table.filters;

      if (this.advFilters.from && this.advFilters.to) {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");
        const to = moment(this.advFilters.to).format("YYYY-MM-DD");

        filters.created_at.value = [from, to];

        filters.created_at.matchMode
          = FilterMatchMode.BETWEEN;
      } else {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");

        filters.created_at.value
          = from;

        filters.created_at.matchMode
          = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
      }

      // Filtro per Utente 
      filters.user_id.value = this.advFilters?.user?.id;

      // Filtro per Shift
      filters.shift_id.value = this.advFilters?.shift?.id;
    }

    this._updateTable();
  }

  private _updateTable() {
    const table = (this.$refs.table as Table);
    table.applyFilter();
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },

      created_at: {
        value: null,
        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      },

      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },

      user_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      shift_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
    };
  }

  created() {
    this.initFilter();
  }

}